function Contact() {
  return (
    <div
      className="contact mt-32 flex flex-col items-center justify-center"
      id="kontakt"
    >
      <div className="head flex flex-col text-center items-center justify-center">
        <h1 className="font-code font-bold text-2xl">Kontakt</h1>
        <h2 className="font-text text-indigo-200">
          Kontaktiere den Entwickler von Lila
        </h2>
      </div>

      <div className="info flex flex-col md:flex-row font-text my-10 text-center">
        <div className="flex flex-col pr-5 text-lg pb-4 md:pb-0">
          <p>
            <span className="text-indigo-300">Entwickler:</span> Simon W.
          </p>
          <p
            onClick={() => {
              navigator.clipboard.writeText("info@lila.codes");
            }}
            className="cursor-pointer"
          >
            <span className="text-indigo-300">Email:</span> info@lila.codes
          </p>
        </div>
        <div className="flex flex-row justify-center items-center">
          <a
            href="mailto:info@lila.codes?subject=Kontaktanfrage%20durch%20lila.codes&body=%5BNachricht%5D%0D%0A"
            className="hover:scale-95 transition-all duration-500 text-white font-text bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-400 font-medium rounded-lg text-sm px-4 py-3 text-center"
          >
            Kontakt via Email aufnehmen
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
