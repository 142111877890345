import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div
      id="error-page"
      className="bg-gradient-to-r from-gray-900 via-indigo-950 to-gray-900 text-white h-screen w-screen font-text flex flex-col justify-center items-center"
    >
      <h2 className="text-4xl font-code mb-4">404</h2>
      <h1 className="text-white text-6xl font-bold font-code">Fehler</h1>
      <p className="text-xl mt-5 mb-4">Diese Seite existiert nicht.</p>
      <Link
        to="/"
        className="transition-all duration-500 text-white font-text bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-400 font-medium rounded-lg text-sm px-4 py-3 text-center"
      >
        Zurück zur Startseite
      </Link>
    </div>
  );
}
