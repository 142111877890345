function Footer() {
  return (
    <footer className="text-center font-text mt-20 mb-10">
      &copy; {new Date().getFullYear()}{" "}
      <span className="text-indigo-500 font-semibold">lila.codes</span>
      <br />
      <p className="text-gray-300">
        Ein Projekt der{" "}
        <a
          className="text-indigo-300 hover:text-indigo-500 transition-all"
          href="https://st-anna.de"
          target="_blank"
          rel="noreferrer"
        >
          St. Anna Schule Wuppertal
        </a>{" "}
        von Simon W.
      </p>
    </footer>
  );
}

export default Footer;
