import { FaRegCirclePlay } from "react-icons/fa6";

function Hero() {
  return (
    <div className="flex flex-col justify-center items-center font-text h-screen">
      <h2 className="text-lg">lila.codes</h2>
      <h1 className="font-code text-5xl font-bold py-2">Lila</h1>
      <h3 className="text-md text-white/80 text-center">
        Eine einfache Programmiersprache, um jungen Lernenden den <br /> Umstieg
        auf die textbasierte Entwicklung zu erleichtern.
      </h3>
      <div className="hidden xl:block mt-10 h-[500px] w-[1000px] bg-gray-950/40 rounded-2xl px-6 border-2 border-white/20 divide-y divide-white/20">
        <div className="head flex flex-row items-center justify-between pt-3 pb-2">
          <div>
            <p className="font-code text-sm">helloworld.lila</p>
          </div>
          <button className="bg-indigo-700 flex flex-row items-center justify-center text-sm rounded-md px-6 py-1 transition-all hover:bg-indigo-900">
            <FaRegCirclePlay size={25} className="pr-2" />
            <p className="font-code">Run</p>
          </button>
        </div>
        <div
          className="flex flex-col justify-between max-h-full divide-y divide-white/20"
          style={{ height: "80%" }}
        >
          <div className="mt-5 code font-code">
            SETZE text = "Hello World!"
            <br />
            WIEDERHOLE 3 MAL
            <br />
            <p style={{ marginLeft: 10 }}>SCHREIBE text</p>
            ENDE
          </div>
          <div className="terminal pt-2">
            <div className="head">
              <p>Terminal</p>
            </div>
            <div className="font-code"> {">"} Hello World!</div>
            <div className="font-code"> {">"} Hello World!</div>
            <div className="font-code"> {">"} Hello World!</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
