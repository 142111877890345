interface FuncCardProps {
  icon: React.ReactElement;
  title: string;
  description: string;
}

function FuncCard(props: FuncCardProps) {
  return (
    <div className="mt-8 max-w-sm h-[275px] rounded-xl overflow-hidden backdrop-blur-sm bg-white/5 hover:scale-95 transform transition duration-500">
      <div className="px-4 pt-6 flex flex-col items-center justify-center text-center">
        <div className="h-[50px]">{props.icon}</div>
        <div className="mt-4 font-text font-bold text-xl mb-2">
          {props.title}
        </div>
        <p className="font-text text-gray-100 text-base">{props.description}</p>
      </div>
    </div>
  );
}

export default FuncCard;
