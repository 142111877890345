import { osName } from "react-device-detect";
import { FaApple, FaLinux, FaWindows } from "react-icons/fa";
import { Link } from "react-router-dom";
import DownloadCard from "../components/DownloadCard";
function DownloadPage() {
  return (
    <div className="bg-gradient-to-r from-gray-900 via-indigo-950 to-gray-900 xl:h-screen text-white font-text flex flex-col justify-center items-center">
      <h2 className="text-center font-code text-xl mt-40 xl:mt-0">
        lila.codes
      </h2>
      <h1 className="text-center text-7xl font-text font-bold">Download</h1>
      <h3 className="mb-32 mt-1 font-text text-center">
        Lade Lila & den Lila-Editor für dein Betriebssystem herunter
      </h3>

      <Link
        to={
          osName === "Windows"
            ? "https://f72.workupload.com/download/LAA5L3CmTaG"
            : ""
        }
        download
        className="transition-all duration-500 text-white font-text bg-indigo-800 hover:bg-indigo-900 focus:ring-2 focus:outline-none focus:ring-indigo-400 font-medium rounded-lg text-md px-8 py-4 text-center"
      >
        Jetzt für <span className="font-bold">{osName}</span> herunterladen
      </Link>
      <p className="text-sm mt-1.5 mb-10 italic">Version: v1.1.0-BETA</p>

      <div className="flex flex-col md:flex-row items-center justify-center flex-wrap">
        <DownloadCard
          name="Windows"
          icon={<FaWindows color="black" size={22} />}
          active={osName === "Windows"}
          link={"https://f72.workupload.com/download/LAA5L3CmTaG"}
        />
        <DownloadCard
          name="Mac OS"
          icon={<FaApple color="black" size={22} />}
          active={osName === "Mac OS"}
          link={""}
        />
        <DownloadCard
          name="Linux"
          icon={<FaLinux color="black" size={22} />}
          active={osName === "Linux"}
          link={""}
        />
      </div>
      <Link
        to={
          "https://gitlab.com/wgnsimon/facharbeit/-/archive/master/facharbeit-master.zip"
        }
        className="text-sm mt-4 text-white/90  font-text px-2 py-1.5"
      >
        Raw Download ohne Python Virtual Environment
      </Link>
      <Link
        to="/"
        className="mt-10 mb-10 backdrop-blur-sm bg-white/5 transition-all duration-500 text-white font-text focus:ring-2 focus:outline-none focus:ring-indigo-400 font-medium rounded-lg text-sm px-4 py-2 text-center"
      >
        Zurück zur Startseite
      </Link>
    </div>
  );
}
export default DownloadPage;
