import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav className="mx-auto mt-2 sm:pl-20 pl-10 sm:pr-14 rounded-xl z-20 top-0 backdrop-blur-sm bg-white/5">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3 px-2">
        <a
          href="/"
          className="flex items-center space-x-3 rtl:space-x-reverse sm:pr-14 pr-10"
        >
          <img src={logo} height={40} width={40} alt="" />
          <span className="self-center text-2xl font-code font-bold whitespace-nowrap">
            Lila
          </span>
        </a>
        <div className="flex xl:order-2 space-x-3 xl:space-x-0 rtl:space-x-reverse sm:pl-14">
          <Link
            to={"/download"}
            type="button"
            className="transition-all duration-500 text-white font-text bg-indigo-700 hover:bg-indigo-800 focus:ring-2 focus:outline-none focus:ring-indigo-400 font-medium rounded-lg text-sm px-4 py-2 text-center "
          >
            Download
          </Link>
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg xl:hidden hover:bg-indigo-900 focus:outline-none "
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={
            "items-center justify-between w-full xl:flex xl:w-auto xl:order-1 " +
            (menuOpen ? "" : "hidden")
          }
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 xl:p-0 mt-4 font-medium  rounded-lg xl:space-x-8 rtl:space-x-reverse xl:flex-row xl:mt-0 xl:border-0  ">
            <li>
              <a
                href="/"
                className="transition-all duration-300 block py-2 px-3 font-text text-white bg-indigo-700 rounded-xl xl:bg-transparent xl:text-indigo-600 xl:p-0 "
              >
                Home
              </a>
            </li>
            <li>
              <AnchorLink
                href="#funktionen"
                className="transition-all duration-300 block py-2 px-3 font-text text-white rounded-xl hover:bg-white/5 xl:hover:bg-transparent xl:hover:text-indigo-600 xl:p-0 "
              >
                Funktionen
              </AnchorLink>
            </li>
            <li>
              <a
                href="https://docs.lila.codes"
                className="transition-all duration-300 block py-2 px-3 font-text text-white rounded-xl hover:bg-white/5 xl:hover:bg-transparent xl:hover:text-indigo-600 xl:p-0 "
              >
                Doku
              </a>
            </li>
            <li>
              <AnchorLink
                href="#kontakt"
                className="transition-all duration-300 block py-2 px-3 font-text text-white rounded-xl hover:bg-white/5 xl:hover:bg-transparent xl:hover:text-indigo-600 xl:p-0 "
              >
                Kontakt
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
