import {
  FaCode,
  FaGraduationCap,
  FaInfinity,
  FaLanguage,
  FaPlug,
} from "react-icons/fa";
import FuncCard from "../components/FuncCard";

function Functionality() {
  return (
    <div className="funcs" id="funktionen">
      <div className="head flex flex-col text-center items-center justify-center">
        <h1 className="font-code font-bold text-2xl">Funktionen</h1>
        <h2 className="font-text text-indigo-200">
          Einige Funktionen & Vorteile im Überblick
        </h2>
      </div>
      <div className="flex lg:mx-60 lg:flex-row flex-col flex-wrap items-center justify-around">
        <FuncCard
          icon={<FaCode size={50} />}
          title="Steile Lernkurve"
          description="Der einfache Syntax soll Jüngeren helfen, schneller in die große Welt der Programmierung einzusteigen!"
        />
        <FuncCard
          icon={<FaLanguage size={50} />}
          title="Deutscher Syntax"
          description="Der auf deutsch übersetze Quellcode, verhindert Missverständnisse und erleichtert das Verständnis!"
        />
        <FuncCard
          icon={<FaPlug size={50} />}
          title="Plug and Play"
          description="Schüler sparen sich mit Lila ganz einfach den nervigen Kompilierungsprozess und können direkt losprogrammieren!"
        />
        <FuncCard
          icon={<FaGraduationCap size={50} />}
          title="Von Schülern, für Schüler"
          description="Das Projekt ist von Schülern und Lehrern direkt auf Schüler zugeschnitten und dient nur des Lernens!"
        />
        <FuncCard
          icon={<FaInfinity size={50} />}
          title="Endlose Möglichkeiten"
          description="Auch fortgeschrittene Schüler können mit Lila ihre Fähigkeiten erweitern und ohne Limit programmieren!"
        />
      </div>
      <p className="text-center mt-10 font-text text-lg text-">
        ... und noch vieles mehr!
      </p>
    </div>
  );
}

export default Functionality;
