import "./App.css";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import Functionality from "./sections/Functionality";
import Hero from "./sections/Hero";
import Navigation from "./sections/Navigation";

function App() {
  return (
    <div className="bg-gradient-to-r from-gray-900 via-indigo-950 to-gray-900 text-white">
      <div className="flex flex-col justify-between">
        <Navigation />
        <Hero />
        <Functionality />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
