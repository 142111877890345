import { Link } from "react-router-dom";

interface DownloadCardProps {
  name: string;
  icon: React.ReactElement;
  active: boolean;
  link: string;
}

function DownloadCard(props: DownloadCardProps) {
  return (
    <div
      className={
        "mt-8 max-w-sm rounded-xl overflow-hidden backdrop-blur-sm bg-white/10 md:mr-10 " +
        (props.active ? "ring-1 ring-white/30" : "")
      }
    >
      <div className="px-4 pt-6 flex flex-col items-start justify-center text-center my-4 mx-2">
        <div className="flex flex-row items-center justify-center mb-4">
          <div className="w-10 h-10 rounded-full bg-white flex justify-center items-center">
            {props.icon}
          </div>
          <div className="ml-2 font-bold">{props.name}</div>
        </div>
        {props.link !== "" ? (
          <Link
            to={props.link}
            download
            className="transition-all duration-500 text-white mb-4 mt-1 bg-white/10 hover:bg-white/20 focus:ring-2 focus:outline-none focus:ring-white font-text rounded-lg text-sm px-4 py-3 text-center"
          >
            Lade Lila jetzt für <span className="font-bold">{props.name}</span>{" "}
            herunter
          </Link>
        ) : (
          <div className="cursor-pointer transition-all duration-500 text-white mb-4 mt-1 bg-white/10 hover:bg-white/20 focus:ring-2 focus:outline-none focus:ring-white font-text rounded-lg text-sm px-4 py-3 text-center">
            Lade Lila jetzt für <span className="font-bold">{props.name}</span>{" "}
            herunter
          </div>
        )}
      </div>
    </div>
  );
}

export default DownloadCard;
